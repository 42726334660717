// docFiscaleSlice.js
import { createSlice } from '@reduxjs/toolkit'
import moment from 'moment'
function formattaPrezzi(articoli) {
    if (articoli && articoli.length) return articoli.map(articolo => {
        const prezzoUnitarioFormattato = parseFloat(articolo.prezzoUnitario).toFixed(2).replace('.', ',')
        const scontoFormattato = parseFloat(articolo.sconto).toFixed(2).replace('.', ',')

        return {
            ...articolo,
            prezzoUnitario: `${prezzoUnitarioFormattato}`,
            sconto: `${scontoFormattato}`
        }
    })
    return []
}

const initialState = {
    id: 0,
    headerDocumento: {
        nomeDocumento: '',
        tipoDocumento: '',
        idSezionale: '',
        numeroDocumento: 0,
        annoFiscale: '',
        isPubblicaAmministrazione: '',
        formatoTrasmissione: ''
    },
    pubblicaAmministrazione: {
        codiceDestinatario: '',
        denominazione: '',
        riferimentoPA: ''
    },
    numeroDocumentoValorizzato: true,
    idMagazzino: 0,
    dataDocumento: moment().format('YYYY-MM-DD'),
    idListino: 0,
    idCollaboratore: 0,
    prodotti: [],
    anagraficaCliente: {},
    indirizzoSpedizione: {},
    causale: 'Causale',
    note: 'Note',
    dettaglioPagamento: {
        tipoPagamento: { value: 'MP01', descrizione: 'Contanti' },
        coordinateBancarie: '',
        nome: '',
        cognome: '',
        codiceFiscale: '',
        comunicaSdi: false,
        comunicaPrimaNota: true,
        gridData: [
            {
                id: 1,
                dataScadenzaPagamento: moment().format('YYYY-MM-DD'),
                dataSaldo: '',
                importo: '',
                saldato: false,
                risorsaContabile: { label: '', value: '' },
                riferimentoPagamento: ''
            }
        ]
    },
    isSameAddress: true,
    infoTrasporto: {},
    subtotale: 0,
    scontoTotale: 0,
    riferimenti: [],
    totale: 0,
    aliquotaIva: {},
    contributoPrevidenziale: {},
    ritenuta: {},
    allegati: {}
}

const docFiscaleSlice = createSlice({
    name: 'docFiscale',
    initialState,
    reducers: {
        DOC_FISCALE_VALORIZZATO: (state, { payload }) => {
            const prodottiFormattati = formattaPrezzi(payload.prodotti)

            Object.assign(state, {
                id: Number(payload.id),
                headerDocumento: payload.headerDocumento,
                pubblicaAmministrazione: payload.pubblicaAmministrazione,
                numeroDocumentoValorizzato: true,
                idMagazzino: payload.idMagazzino,
                dataDocumento: payload.dataDocumento,
                idListino: payload.idListino,
                idCollaboratore: payload.idCollaboratore,
                prodotti: prodottiFormattati,
                anagraficaCliente: payload.anagraficaCliente,
                indirizzoSpedizione: payload.indirizzoSpedizione || {},
                causale: payload.causale,
                note: payload.note,
                dettaglioPagamento: payload.dettaglioPagamento,
                infoTrasporto: payload.infoTrasporto,
                subtotale: payload.subtotale,
                scontoTotale: payload.scontoTotale,
                isSameAddress: payload.isSameAddress,
                riferimenti: payload.riferimenti,
                totale: payload.totale,
                aliquotaIva: payload.aliquotaIva,
                contributoPrevidenziale: payload.contributoPrevidenziale,
                ritenuta: payload.ritenuta,
                allegati: payload.allegati || {}
            })
        },
        MODIFICA_TIPO_DOCUMENTO: (state, { payload }) => {
            state.headerDocumento.tipoDocumento = payload.value
        },
        MODIFICA_SEZIONALE: (state, { payload }) => {
            state.headerDocumento.idSezionale = payload.value
            state.headerDocumento.isPubblicaAmministrazione = payload.isPubblicaAmministrazione
        },
        MODIFICA_NUMERO_DOCUMENTO: (state, { payload }) => {
            state.headerDocumento.numeroDocumento = payload
            state.numeroDocumentoValorizzato = true
        },
        MODIFICA_ANNO_FISCALE: (state, { payload }) => {
            state.headerDocumento.annoFiscale = payload.value
        },
        MODIFICA_NOME_DOCUMENTO: (state, { payload }) => {
            state.headerDocumento.nomeDocumento = payload.isFatturaElettronica ? 'fattura-elettronica' : payload.docType
        },
        MODIFICA_RIFERIMENTO_AMMINISTRAZIONE: (state, { payload }) => {
            state.riferimentoAmministrazione = payload
        },
        AGGIORNAMENTO_ANAGRAFICA: (state, { payload }) => {
            state.anagraficaCliente = payload
            state.indirizzoSpedizione = {}
            state.headerDocumento.formatoTrasmissione = payload.tipoContatto === 'Pubblica Amministrazione' ? 'FPA12' : 'FPR12'
        },
        SET_FIELD_DOC: (state, { payload }) => {
            state[payload.field] = payload.value
        },
        RESET_PAGAMENTI: (state) => {
            // Calcolo della somma degli importi in dettaglioPagamento.gridData
            const sommaImporti = state.dettaglioPagamento.gridData.reduce((acc, curr) => {
                return acc + parseFloat(curr.importo)
            }, 0)

            // Arrotondamento della sommaImporti a due cifre decimali per confronto corretto
            const sommaImportiArrotondata = sommaImporti.toFixed(2)

            // Confronto della somma degli importi arrotondata con state.totale arrotondato
            if (sommaImportiArrotondata !== state.totaleDovuto.toFixed(2)) {
                state.dettaglioPagamento.gridData = [
                    {
                        id: 1,
                        dataScadenzaPagamento: moment().format('YYYY-MM-DD'),
                        dataSaldo: '',
                        importo: state.totaleDovuto.toFixed(2),
                        saldato: false,
                        risorsaContabile: { label: '', value: '' },
                        riferimentoPagamento: ''
                    }
                ]
            }
        },
        TOTALE_PAGAMENTO_PAGAMENTI: (state, { payload }) => {
            if (state.dettaglioPagamento.gridData.length > 0) {
                state.dettaglioPagamento.gridData[0].importo = Number(payload).toFixed(2)
            }
        },
        AGGIUNGI_RIGA_PAGAMENTI: (state, { payload }) => {
            state.dettaglioPagamento.gridData.push(payload)
        },
        MODIFICA_TIPO_PAGAMENTO_PAGAMENTI: (state, { payload }) => {
            state.dettaglioPagamento.tipoPagamento = payload
        },
        MODIFICA_NOME_PAGAMENTI: (state, { payload }) => {
            state.dettaglioPagamento.nome = payload
        },
        MODIFICA_COGNOME_PAGAMENTI: (state, { payload }) => {
            state.dettaglioPagamento.cognome = payload
        },
        MODIFICA_CODICE_FISCALE_PAGAMENTI: (state, { payload }) => {
            state.dettaglioPagamento.codiceFiscale = payload
        },
        SET_COMUNICA_PRIMANOTA_PAGAMENTI: (state, { payload }) => {
            state.dettaglioPagamento.comunicaPrimaNota = payload
        },
        SET_COMUNICA_SDI_PAGAMENTI: (state, { payload }) => {
            state.dettaglioPagamento.comunicaSdi = payload
        },
        MODIFICA_COORDINATE_BANCARIE_PAGAMENTI: (state, { payload }) => {
            state.dettaglioPagamento.coordinateBancarie = payload
        },
        SET_DATA_SCADENZA_PAGAMENTO_PAGAMENTI: (state, { payload }) => {
            const index = state.dettaglioPagamento.gridData.findIndex(e => e.id === payload.id)
            if (index !== -1) {
                state.dettaglioPagamento.gridData[index].dataScadenzaPagamento = moment(payload.value).format('YYYY-MM-DD')
            }
        },
        SET_DATA_SALDO_PAGAMENTI: (state, { payload }) => {
            const index = state.dettaglioPagamento.gridData.findIndex(e => e.id === payload.id)
            if (index !== -1) {
                state.dettaglioPagamento.gridData[index].dataSaldo = moment(payload.value).format('YYYY-MM-DD')
            }
        },
        SET_RIGA_IMPORTO_PAGAMENTI: (state, { payload }) => {
            const newGridDataSetImporto = JSON.parse(JSON.stringify(state.dettaglioPagamento.gridData))
            const indexAltraRiga = newGridDataSetImporto.findIndex(e => e.id === payload.id)
            if (indexAltraRiga !== -1) {
                newGridDataSetImporto[indexAltraRiga].importo = payload.value
                const indexRigaPrimaria = newGridDataSetImporto.findIndex(e => e.id === 1)
                const totaleAcconto = newGridDataSetImporto.reduce((acc, item) => {
                    if (item.id !== 1) {
                        return acc + Number(item.importo)
                    }
                    return acc
                }, 0)
                newGridDataSetImporto[indexRigaPrimaria].importo = (payload.totalePagamento - totaleAcconto).toFixed(2)
            }
            state.dettaglioPagamento.gridData = newGridDataSetImporto
        },
        SET_RIGA_SALDATO_PAGAMENTI: (state, { payload }) => {
            const index = state.dettaglioPagamento.gridData.findIndex(e => e.id === payload.id)
            if (index !== -1) {
                state.dettaglioPagamento.gridData[index].saldato = payload.value
            }
        },

        SET_RIGA_RISORSA_CONTABILE_PAGAMENTI: (state, { payload }) => {
            const index = state.dettaglioPagamento.gridData.findIndex(e => e.id === payload.id)
            if (index !== -1) {
                state.dettaglioPagamento.gridData[index].risorsaContabile = payload.value
            }
        },
        SET_RIGA_RIFERIMENTO_PAGAMENTO_PAGAMENTI: (state, { payload }) => {
            const index = state.dettaglioPagamento.gridData.findIndex(e => e.id === payload.id)
            if (index !== -1) {
                state.dettaglioPagamento.gridData[index].riferimentoPagamento = payload.value
            }
        },

        DELETE_RIGA_PAGAMENTI: (state, { payload }) => {
            const newGridData = JSON.parse(JSON.stringify(state.dettaglioPagamento.gridData))
            newGridData.splice(payload.index, 1)

            const totaleAccontoAfterElimination = newGridData.reduce((acc, item, i) => {
                if (i !== 0) {
                    return acc + Number(item.importo)
                }
                return acc
            }, 0)
            if (newGridData.length > 0) {
                newGridData[0].importo = (payload.totalePagamento - totaleAccontoAfterElimination).toFixed(2)
            }

            state.dettaglioPagamento.gridData = newGridData
        },
        SET_FIELD_TRASPORTO_DOC: (state, { payload }) => {
            state.infoTrasporto[payload.field] = payload.value || ''
        },
        SET_ALLEGATI_DOC: (state, { payload }) => {
            state.allegati = payload
        },
        AGGIUNGI_RIGA_RIFERIMENTI: (state, { payload }) => {
            state.riferimenti = [...state.riferimenti, payload]
        },

        RIMUOVI_RIGA_RIFERIMENTI: (state, { payload }) => {
            const newRighe = [...state.riferimenti]
            newRighe.splice(payload, 1)
            state.riferimenti = newRighe
        },

        MODIFICA_TIPO_DOCUMENTO_RIFERIMENTI: (state, { payload }) => {
            const newRighe = [...state.riferimenti]
            newRighe[payload.index].tipoDocumentoRiferimento = payload.value
            state.riferimenti = newRighe
        },

        MODIFICA_DATA_DOCUMENTO_RIFERIMENTI: (state, { payload }) => {
            const newRighe = [...state.riferimenti]
            newRighe[payload.index].dataDocumento = moment(new Date(payload.value)).format("YYYY-MM-DD")
            state.riferimenti = newRighe
        },

        MODIFICA_NUMERO_DOCUMENTO_RIFERIMENTI: (state, { payload }) => {
            const newRighe = [...state.riferimenti]
            newRighe[payload.index].idDocumentoRiferimento = payload.value
            state.riferimenti = newRighe
        },

        MODIFICA_CODICE_CIG_RIFERIMENTI: (state, { payload }) => {
            const newRighe = [...state.riferimenti]
            newRighe[payload.index].codiceCig = payload.value
            state.riferimenti = newRighe
        },

        MODIFICA_CODICE_CUP_RIFERIMENTI: (state, { payload }) => {
            const newRighe = [...state.riferimenti]
            newRighe[payload.index].codiceCup = payload.value
            state.riferimenti = newRighe
        },

        MODIFICA_COMMESSA_CONVENZIONE_RIFERIMENTI: (state, { payload }) => {
            const newRighe = [...state.riferimenti]
            newRighe[payload.index].codiceCommessaConvenzione = payload.value
            state.riferimenti = newRighe
        },

        ASSOCIA_RIGHE_RIFERIMENTI: (state, { payload }) => {
            // Assumendo che il payload includa { index, righeDaAssociare }
            // dove `index` è l'indice dell'elemento di `riferimenti` da aggiornare,
            // e `righeDaAssociare` è l'array delle nuove righe da associare.
            const { index, righeDaAssociare } = payload

            if (state.riferimenti[index]) {
                // Filtra righeDaAssociare per includere solo quelle con `associato === true`
                const righeFiltrate = righeDaAssociare.filter(riga => riga.associato === true)
                // Aggiorna `linee` per l'elemento specificato in `riferimenti`
                state.riferimenti[index].linee = righeFiltrate
            }
        },
        AGGIORNA_RIFERIMENTI_CON_RIGHEGRID: (state, { payload }) => {
            // payload contiene l'array attuale di righeGrid
            const righeGridIds = payload.map(riga => riga.idInterno) // Assumiamo che ogni riga in righeGrid abbia un id univoco

            // Itera su ogni riferimento esistente
            const riferimentiAggiornati = state.riferimenti.map(riferimento => {
                // Filtra le linee di ogni riferimento mantenendo solo quelle presenti in righeGrid
                const lineeAggiornate = riferimento.linee.filter(linea => righeGridIds.includes(linea.idInterno))

                return {
                    ...riferimento,
                    linee: lineeAggiornate
                }
            })

            // Aggiorna i riferimenti nello stato con le linee filtrate
            state.riferimenti = riferimentiAggiornati
        },
        CALCOLO_SUBTOTALE: (state, { payload }) => {
            state.subtotale = payload.toFixed(2)
        },

        CALCOLO_TOTALE: (state, { payload }) => {
            state.totale = payload.toFixed(2)
        },

        CALCOLO_TOTALEIVA: (state, { payload }) => {
            state.aliquotaIva = {
                ivaTotale: payload.ivaTotale,
                aliquoteIva: payload.aliquoteIva
            }
        },

        CALCOLO_TOTALESCONTO: (state, { payload }) => {
            state.scontoTotale = payload
        },

        CALCOLO_TOTALE_RITENUTA: (state, { payload }) => {
            state.totaleRitenuta = Number(payload.totaleRitenuta).toFixed(2)
            state.totaleDovuto = Number(payload.totaleDovuto).toFixed(2)
        },

        PRODOTTO_AGGIUNTO_GRID: (state, { payload }) => {
            const prodottiDaAggiungere = []
            let maxIdInterno = 0
            state.prodotti.forEach(item => {
                if (item.idInterno !== null && item.idInterno > maxIdInterno) {
                    maxIdInterno = item.idInterno
                }
            })
            const payloadMapped = payload.map((el, i) => ({
                descrizione: el.nome,
                aliquotaIVA: { value: el.aliquotaIva, codiceNatura: el.natura, text: el.descrizioneAliquota },
                prezzoUnitario: Intl.NumberFormat('it-IT').format(el.prezzoVendita),
                quantita: String(1),
                prezzoTotale: Intl.NumberFormat('it-IT').format(Number(el.prezzoVendita) * 1),
                quantitaDisponibile: el.quantita,
                codice: el.codice,
                unitaMisura: { text: el.unitaMisura, value: el.unitaMisura },
                id: el.id,
                idInterno: maxIdInterno + i + 1,
                sconto: String(0),
                scaricaDalMagazzino: true,
                ritenuta: false
            }))
            payloadMapped.forEach(e => {
                if (!state.prodotti.find(element => element.id === e.id)) {
                    prodottiDaAggiungere.push(e)
                }
            })
            return {
                ...state,
                prodotti: [...state.prodotti, ...prodottiDaAggiungere]
            }
        },


        PRODOTTO_AGGIUNTO_MANUALMENTE_GRID: (state, { payload }) => {
            state.prodotti = [...state.prodotti, payload]
        },

        CALCOLO_CONTRIBUTO_PREVIDENZIALE: (state, { payload }) => {
            state.contributoPrevidenziale = {
                ...state.contributoPrevidenziale,
                ...payload
            }
        },

        INCLUDE_CASSA: (state, { payload }) => {
            state.contributoPrevidenziale.includeCassa = payload
        },

        INCLUDE_RITENUTA: (state, { payload }) => {
            state.ritenuta.includeRitenuta = payload
        },

        MODIFICA_RITENUTA_PERCENTUALE: (state, { payload }) => {
            state.ritenuta.valoreRitenutaPercentuale = payload
        },

        MODIFICA_MONTANTE_PERCENTUALE: (state, { payload }) => {
            state.ritenuta.montantePercentuale = payload
        },
        MODIFICA_CONTRIBUTO_PREVIDENZIALE: (state, { payload }) => {
            state.contributoPrevidenziale.contributoPrevidenzialePercentuale = payload.toFixed(2)
        },
        AGGIORNA_RIGA_GRID: (state, { payload }) => {
            const prodottiAggiornati = state.prodotti.map(prodotto => {
                if (prodotto.idInterno === payload.idInterno) {
                    // Qui si assume che `payload` contenga l'intero nuovo oggetto prodotto
                    // che sostituirà quello vecchio. Puoi adattarlo se necessario.
                    return { ...prodotto, ...payload }
                }
                return prodotto
            })
            return { ...state, prodotti: prodottiAggiornati }
        },
        ELIMINA_PRODOTTO_GRID: (state, { payload }) => {
            // Filtra l'array di prodotti per rimuovere il prodotto con l'idInterno specificato
            const prodottiFiltrati = state.prodotti.filter(prodotto => prodotto.idInterno !== payload.idInterno)

            return { ...state, prodotti: prodottiFiltrati }
        },
        CALCOLA_TOTALI: (state) => {
            let totalPriceRows = 0
            let totalDiscount = 0
            let totalIvaComplessivo = 0
            let contributoPrevidenziale = 0
            let totaleRitenuta = 0
            const aliquoteIvaMap = new Map()

            // Calcolo del totale dei prezzi dei prodotti e dello sconto totale
            state.prodotti.forEach(prodotto => {
                const prezzoTotale = parseFloat(prodotto.prezzoTotale.replace(",", "."))
                totalPriceRows += prezzoTotale

                const scontoPerc = parseFloat(prodotto.sconto.replace(",", ".")) || 0
                const discountAmount = (scontoPerc / 100) * prezzoTotale
                totalDiscount += discountAmount

                // Calcolo dell'IVA per ogni prodotto
                if (prodotto.aliquotaIVA && prodotto.aliquotaIVA.value) {
                    const aliquotaIVA = parseFloat(prodotto.aliquotaIVA.value.replace(",", ".")) || 0
                    const imposta = prezzoTotale * aliquotaIVA / 100
                    const aliquotaKey = aliquotaIVA.toFixed(2)

                    if (!aliquoteIvaMap.has(aliquotaKey)) {
                        aliquoteIvaMap.set(aliquotaKey, {
                            imponibile: prezzoTotale.toFixed(2),
                            aliquotaIva: aliquotaKey,
                            natura: prodotto.aliquotaIVA.codiceNatura || "",
                            imposta: imposta.toFixed(2),
                            esigibilitaIva: state.esigibilitaIva || "",
                            riferimentoNormativo: prodotto.aliquotaIVA.text || "",
                            isContributoPrevidenziale: false
                        })
                    } else {
                        const data = aliquoteIvaMap.get(aliquotaKey)
                        data.imponibile = (parseFloat(data.imponibile) + prezzoTotale).toFixed(2)
                        data.imposta = (parseFloat(data.imposta) + imposta).toFixed(2)
                    }

                    // Arrotonda l'imposta IVA a due decimali
                    const impostaArrotondata = parseFloat(imposta.toFixed(2))

                    totalIvaComplessivo += impostaArrotondata
                }
            })

            if (state.contributoPrevidenziale && state.contributoPrevidenziale.includeCassa) {
                const percentuale = state.contributoPrevidenziale.contributoPrevidenzialePercentuale
                const percentualeNumerica = parseFloat(percentuale ? percentuale.toString().replace(",", ".") : "0")
                contributoPrevidenziale = totalPriceRows * (percentualeNumerica / 100)
                state.contributoPrevidenziale.imponibileContributoPrevidenziale = totalPriceRows.toFixed(2)

                // Ottieni l'aliquota IVA e la natura del primo prodotto, se esiste
                const primoProdotto = state.prodotti[0]
                const aliquotaIvaContributo = primoProdotto && primoProdotto.aliquotaIVA && primoProdotto.aliquotaIVA.value ? parseFloat(primoProdotto.aliquotaIVA.value.replace(",", ".")) : 0
                const naturaIvaContributo = primoProdotto && primoProdotto.aliquotaIVA && primoProdotto.aliquotaIVA.codiceNatura ? primoProdotto.aliquotaIVA.codiceNatura : ""

                const impostaContributoPrevidenziale = contributoPrevidenziale * aliquotaIvaContributo / 100
                totalIvaComplessivo += impostaContributoPrevidenziale

                // Genera un identificatore unico per il contributo previdenziale
                const contributoPrevidenzialeKey = `Contributo-${aliquotaIvaContributo.toFixed(2)}`

                // Aggiungi sempre come una nuova voce dedicata al contributo previdenziale
                aliquoteIvaMap.set(contributoPrevidenzialeKey, {
                    imponibile: contributoPrevidenziale.toFixed(2),
                    aliquotaIva: aliquotaIvaContributo.toFixed(2),
                    natura: naturaIvaContributo,
                    imposta: impostaContributoPrevidenziale.toFixed(2),
                    esigibilitaIva: state.esigibilitaIva || "",
                    riferimentoNormativo: primoProdotto.aliquotaIVA.text,
                    isContributoPrevidenziale: true
                })
            }
            if (state.ritenuta && state.ritenuta.includeRitenuta) {
                const percentuale = state.ritenuta.valoreRitenutaPercentuale
                totaleRitenuta = state.prodotti.reduce((acc, prodotto) => {
                    if (prodotto.ritenuta) {
                        return acc + parseFloat(prodotto.prezzoTotale.replace(",", "."))
                    }
                    return acc
                }, 0) * (parseFloat(percentuale ? percentuale.toString().replace(",", ".") : '0.00') / 100)
            }

            const totale = totalPriceRows - totalDiscount + totalIvaComplessivo + contributoPrevidenziale
            const totaleDovuto = totale - totaleRitenuta

            state.subtotale = totalPriceRows
            state.scontoTotale = totalDiscount
            state.totaleIVA = totalIvaComplessivo
            state.contributoPrevidenziale.valoreContributoPrevidenziale = contributoPrevidenziale.toFixed(2)
            state.ritenuta.totaleRitenuta = totaleRitenuta
            state.totale = totale
            state.totaleDovuto = totaleDovuto

            // Aggiornamento delle aliquote IVA nello stato
            state.aliquotaIva = {
                ivaTotale: totalIvaComplessivo.toFixed(2),
                aliquoteIva: Array.from(aliquoteIvaMap.values())
            }
        }
    }
})
export const {
    DOC_FISCALE_VALORIZZATO,
    MODIFICA_TIPO_DOCUMENTO,
    MODIFICA_SEZIONALE,
    MODIFICA_NUMERO_DOCUMENTO,
    MODIFICA_ANNO_FISCALE,
    MODIFICA_NOME_DOCUMENTO,
    MODIFICA_RIFERIMENTO_AMMINISTRAZIONE,
    AGGIORNAMENTO_ANAGRAFICA,
    SET_FIELD_DOC,
    TOTALE_PAGAMENTO_PAGAMENTI,
    AGGIUNGI_RIGA_PAGAMENTI,
    MODIFICA_TIPO_PAGAMENTO_PAGAMENTI,
    MODIFICA_NOME_PAGAMENTI,
    MODIFICA_COGNOME_PAGAMENTI,
    MODIFICA_CODICE_FISCALE_PAGAMENTI,
    SET_COMUNICA_PRIMANOTA_PAGAMENTI,
    SET_COMUNICA_SDI_PAGAMENTI,
    MODIFICA_COORDINATE_BANCARIE_PAGAMENTI,
    SET_DATA_SCADENZA_PAGAMENTO_PAGAMENTI,
    SET_DATA_SALDO_PAGAMENTI,
    SET_RIGA_IMPORTO_PAGAMENTI,
    SET_RIGA_SALDATO_PAGAMENTI,
    SET_RIGA_RISORSA_CONTABILE_PAGAMENTI,
    SET_RIGA_RIFERIMENTO_PAGAMENTO_PAGAMENTI,
    DELETE_RIGA_PAGAMENTI,
    SET_FIELD_TRASPORTO_DOC,
    SET_ALLEGATI_DOC,
    AGGIUNGI_RIGA_RIFERIMENTI,
    RIMUOVI_RIGA_RIFERIMENTI,
    MODIFICA_TIPO_DOCUMENTO_RIFERIMENTI,
    MODIFICA_DATA_DOCUMENTO_RIFERIMENTI,
    MODIFICA_NUMERO_DOCUMENTO_RIFERIMENTI,
    MODIFICA_CODICE_CIG_RIFERIMENTI,
    MODIFICA_CODICE_CUP_RIFERIMENTI,
    MODIFICA_COMMESSA_CONVENZIONE_RIFERIMENTI,
    ASSOCIA_RIGHE_RIFERIMENTI,
    CALCOLO_SUBTOTALE,
    CALCOLO_TOTALE,
    CALCOLO_TOTALEIVA,
    CALCOLO_TOTALESCONTO,
    CALCOLO_TOTALE_RITENUTA,
    PRODOTTO_AGGIUNTO_GRID,
    PRODOTTO_AGGIUNTO_MANUALMENTE_GRID,
    CALCOLO_CONTRIBUTO_PREVIDENZIALE,
    INCLUDE_CASSA,
    INCLUDE_RITENUTA,
    MODIFICA_RITENUTA_PERCENTUALE,
    MODIFICA_MONTANTE_PERCENTUALE,
    MODIFICA_CONTRIBUTO_PREVIDENZIALE,
    AGGIORNA_RIGA_GRID,
    ELIMINA_PRODOTTO_GRID,
    CALCOLA_TOTALI,
    RESET_PAGAMENTI,
    AGGIORNA_RIFERIMENTI_CON_RIGHEGRID
} = docFiscaleSlice.actions

export default docFiscaleSlice.reducer
