import { createSlice } from '@reduxjs/toolkit'

export const booleanSlice = createSlice({
    name: 'booleanSlice',
    initialState: {
        isEditing: false,
        isDisabled: false
    },
    reducers: {
        UPDATE_STATO: (state, action) => {
            state.isEditing = action.payload
        },
        UPDATE_STATO_DISABLE: (state, action) => {
            state.isDisabled = action.payload
        }
    }
})

export const {

    UPDATE_STATO,
    UPDATE_STATO_DISABLE
} = booleanSlice.actions

export default booleanSlice.reducer